@mixin for-small-phone {
  @media (max-width: 320px) {
    @content;
  }
}
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-small-mobile-up {
  @media (min-width: 465px) {
    @content;
  }
}
@mixin for-mobile-up {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 769px) {
    @content;
  }
}
@mixin for-tablet-portrait-down {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-tablet-landscape-down {
  @media (max-width: 1199px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}
