@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
@import '../../styles/breakpoints.scss';

.banner {
  > .flex-container {
    padding: 0;
  }

  &__container {
    position: relative;
    max-width: 1030px;
  }

  &__content {
    padding: space(80) space(16) space(24);

    @include for-tablet-portrait-down {
      padding: space(64) space(32) space(32);
    }

    @include for-phone-only {
      padding: space(40) space(32);
    }

    h2 {
      font-weight: 400;
    }
  }

  &__title {
    margin-bottom: space(8);
    text-transform: uppercase;
  }

  &__description {
    max-width: 632px;

    p {
      font-size: pxtorem(34px);
      line-height: pxtorem(46px);

      @include for-phone-only {
        font-size: pxtorem(16px);
        line-height: pxtorem(22px);
      }
    }
  }

  &__image {
    text-align: right;
    overflow: hidden;

    @include for-phone-only {
      margin-top: space(16);
      text-align: left;
    }

    img {
      object-fit: contain;
      width: 170px;
      height: auto;
    }
  }

  &__carousel {
    position: relative;
    width: 100%;
    margin-bottom: space(40);
    padding: space(16);

    &__card {
      padding: space(32) space(64);
      align-items: center;
      justify-content: space-between;
      border-radius: 20px;
      background-color: #fff;
      width: 100%;
      display: flex;
      @include for-phone-only {
        padding: space(16) space(32);
        display: block;
      }
    }

    @include for-tablet-landscape-down {
      margin-top: space(32);
      margin-bottom: space(16);
    }

    .slide {
      position: relative;
      display: none;

      @include for-tablet-landscape-down {
        flex-wrap: wrap;
        padding: space(16);
      }

      @include for-phone-only {
        padding: space(16);
      }

      &--active {
        display: flex;
      }

      .image {
        position: absolute;
        bottom: calc(100% - 32px);
        right: 10%;
        max-width: 160px;
        height: auto;

        @include for-tablet-landscape-down {
          max-width: 100px;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      .content {
        max-width: 585px;
        margin-bottom: space(24);
        box-sizing: border-box;

        @include for-tablet-portrait-up {
          margin: 0;
          padding-right: space(32);
        }
      }

      .title {
        @include heading-m;
        margin-bottom: space(16);
      }
    }

    .arrows {
      @include for-tablet-landscape-down {
        display: flex;
        justify-content: flex-end;
        margin-top: space(16);
      }
    }

    .arrow {
      width: 54px;
      height: 54px;
      padding: space(16);
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 4px;
      cursor: pointer;

      @include for-desktop-up {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 62px;
        height: 62px;
      }

      &-left {
        left: -72px;
      }

      &-right {
        right: -72px;

        @include for-tablet-landscape-down {
          margin-left: space(16);
        }
      }

      &:not(:disabled) {
        background-color: $white;
      }
    }
  }
}

/** not a fan of this but the existing flex containers in Banner Component arent very re-usable,
one example is a parent adds a justify content to everything in the page and this
needs to align differently. So this is a container just for the CTA buttons on the home page */
.flex-container__align--left {
  display: flex;
  margin: pxtorem(15px);
  > button {
    margin-right: pxtorem(30px)
  }
  @include for-phone-only {
    flex-wrap: wrap;
    > a {
      width: 100%;
    }
  }
}
