@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';
@import '../../styles/breakpoints.scss';
@import '../../styles/fonts.scss';

.favourites {
  padding-bottom: space(64);

  @include for-phone-only {
    padding-bottom: space(32);
  }

  &--container {
    @include for-tablet-portrait-up {
      padding: 0 !important;
    }
  }
}

.favourites__header {
  padding: space(48) 0;
  background-color: $turquoise;

  &--share-container {
    padding: 0 !important;
  }

  &--heading {
    margin-bottom: space(16) !important;

    h1 {
      margin-bottom: space(8) !important;
    }

    @include for-phone-only {
      h1 {
        font-size: $font-body-l;
      }
    }
  }

  &--header-container {
    flex-wrap: wrap;
    background-color: $white;
    padding: space(16);
    color: $black;

    &:first-of-type {
      margin-right: space(16);
    }

    @include for-tablet-portrait-down {
      padding: 0 !important;
    }
  }

  &--inner-container {
    justify-content: flex-start !important;
    width: 100%;
    padding: 0 !important;
  }

  &--share {
    margin-right: space(24);
    color: $black;

    @include for-tablet-portrait-down {
      text-align: center;
      margin-top: space(16);
    }

    p {
      display: inline;
      margin-right: space(8);
    }

    svg {
      margin: 0 space(10);
    }
  }

  &--print {
    @include for-tablet-portrait-down {
      text-align: center;
      margin: space(16) 0;
    }

    button {
      color: $black;
      font-weight: normal !important;
    }
  }
}

.favourites__logo {
  width: pxtorem(65px) !important;
  height: pxtorem(65px);
  border: 1px solid $gray;
  background-color: $white;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  img {
    width: 100%;
  }
}

.favourites__card {
  cursor: default;
  border-color: $black;

  &--footer {
    display: grid;
    align-items: center;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    gap: space(6);
    align-self: flex-end;

    & > div {
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }

    @media(max-width: 340px) {
      align-items: flex-end;
      grid-template-columns: 1fr;

      & > div {
        &:last-child {
          display: block;
          justify-content: initial;
          margin-top: 1rem;
        }
      }
    }

    @media (min-width: 600px) and (max-width: 752px) {
      align-items: flex-end;
      grid-template-columns: 1fr;

      & > div {
        &:last-child {
          display: block;
          justify-content: initial;
          margin-top: 1rem;
        }
      }
    }

    a, button {
      display: flex;
      align-items: center;
      font-size: 1.125rem;
      text-decoration: none;

      svg {
        font-size: 1.5rem;
        margin-left: 0.75rem;
      }
    }
  }


  &--location {
    display: flex;
    align-items: center;
    margin: space(12) 0;
    font-size: pxtorem(14px);
    font-weight: 900;

    p {
      font-size: pxtorem(14px);
      line-height: pxtorem(14px);
    }

    svg {
      margin-right: space(8);
    }
  }

  &--button-row {
    border-top: 2px solid $black;
  }

  &--button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: space(12);
    box-sizing: border-box;

    svg {
      margin-left: space(8);
    }

    &:first-of-type {
      border-right: 2px solid $black;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0 2px 4px 0 rgba(56, 56, 56, 0.5);
    }

    &:focus {
      border: 2px solid $turquoise;
    }

    &--more {
      background-color: $black;
      color: $white;
    }

    @include for-phone-only {
      p {
        font-size: pxtorem(18px);
        vertical-align: middle;
      }
    }
  }

  &--contact {
    margin: space(16) 0 !important;

    &--heading {
      @include heading-s;
      margin: space(8) 0;
    }

    a {
      word-break: break-all;
    }

    .flex-col {
      margin-bottom: space(16) !important;

      .body--s {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.favourites__count {
  color: $black;
  margin: space(32) 0;
  text-align: center;

  @include for-tablet-portrait-down {
    margin: space(16) 0 !important;
    padding: 0 space(24) !important;
  }
}

.favourites__add-more {
  margin-top: space(64);

  @include for-phone-only {
    margin-top: space(32);
    padding: space(32) !important;

    button {
      font-size: pxtorem(18px);
    }
  }
}

.favourites__no-favourites {
  color: $black;
  padding: space(64) !important;

  @include for-tablet-portrait-down {
    padding: space(24) !important;

    h3 {
      font-size: $font-heading-s;
    }
  }
}
