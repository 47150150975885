@import '../../../styles/mixins.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/breakpoints.scss';

.location__address {
  margin-top: space(8) !important;
}

.location__image {
  padding-right: space(32);
  text-align: center;

  img {
    width: 100%;
    height: auto;
    border: 1px solid $black;
  }

  @include for-tablet-portrait-down {
    margin-top: space(24) !important;
  }
}

.location__accessibility {
  margin: space(12) 0 0 0 !important;
  justify-content: flex-start !important;

  svg {
    margin-right: space(8);
    height: pxtorem(27px);
    width: pxtorem(27px);
  }
}

.location__google-maps {
  margin: space(12) 0 0 0 !important;

  a:nth-of-type(2) {
    margin-top: space(8);
  }
}

.location__opening-times {
  margin-top: space(24) !important;

  h3 {
    svg {
      font-size: pxtorem(19px);
    }
  }

  &--header {
    margin-bottom: space(8);

    svg {
      margin-right: space(10);
    }
  }

  &--list {
    p {
      padding-bottom: space(8);
      font-size: $font-base;
    }
  }
}

.location__holiday-times {
  margin-top: space(12);

  button {
    padding: 0;
    color: $turquoise;
  }

  h3 {
    font-family: $font-primary;
    font-weight: normal;
    display: inline;
  }
}

.location__container {
  margin-bottom: space(16) !important;
  background-color: $white;

  &--address {
    @include for-tablet-portrait-up {
      justify-content: flex-end !important;
    }

    @include for-desktop-up {
      justify-content: space-between !important;
    }
  }

  @include for-tablet-landscape-up {
    padding: space(24) !important;
  }
}
