@import '../../styles/colors.scss';
@import '../../styles/grid.scss';
@import '../../styles/mixins.scss';
@import '../../styles/breakpoints.scss';
@import '../../styles/fonts.scss';

.header {
  overflow: hidden;
  padding-bottom: space(32);
  background: $white;
  color: $black;
  text-align: right;

  @include for-tablet-landscape-down {
    z-index: 999;
    padding: 0;

    .flex-col {
      margin-bottom: 0;
    }
  }

  &.active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    overflow: auto;
  }

  .flex-container {
    position: relative;
    margin: 0 auto;

    @include for-tablet-landscape-down {
      height: fit-content;

      align-items: baseline;
      flex: 1 1 auto;
    }
  }

  &--top-row {
    align-items: stretch !important;

    button {
      border-radius: 4px;
    }

    &--button-box {
      display: flex;
      margin-right: space(8);

      &:last-of-type {
        margin-right: 0;
        margin-left: space(8);
      }

      button {
        margin: 0;
      }

      @include for-small-phone {
        flex: 0 0 auto;

        &:last-of-type {
          margin: 0;
        }
      }
    }

    &--favourites {
      @include for-tablet-landscape-down {
        background-color: $turquoise;
      }
    }

    &--favourite {
      @include for-tablet-portrait-down {
        text-align: center;
      }
    }

    @include for-small-phone {
      .goog-te-gadget-simple {
        margin: 0;
      }
    }

    @include for-desktop-up {
      justify-content: flex-end !important;
    }

    @include for-tablet-landscape-down {
      button {
        color: $black;

        svg {
          margin-left: 6px;
        }
      }
    }
  }

  &__brand {
    position: relative;
    padding: 0;
    z-index: 1;

    @include for-tablet-landscape-down {
      padding: space(8) 0 !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: space(16);
      width: 100% !important;

      &--active {
        padding: space(16) !important;
      }

      &--sticky {
        position: fixed;
        background-color: $turquoise;
      }

      &--iceberg {
        @include for-phone-only {
          background-color: $turquoise;
        }
      }

      &--favourites {
        @include for-tablet-landscape-down {
          background-image: url('../../assets/images/backgrounds/favourites-shape-mobile.svg');
          background-repeat: no-repeat;
          background-size: 110% 100%;
        }
      }
    }
  }

  &__menu {
    padding: 0;
  }

  &__navigation {
    margin-top: space(8);

    @include for-tablet-landscape-down {
      display: flex;
      text-align: center;
      margin: space(32) inherit;

      .active & {
        display: block !important;
        z-index: 2;
      }

      a {
        display: block;
        margin-bottom: space(16);
      }
    }
  }

  .logo {
    display: block;
    float: left;
    
    img {
      width: 240px;
      height: auto;

      @include for-phone-only {
        width: 120px;
      }
    }
  }

  .nav-trigger {
    display: flex;
    align-items: center;
    margin: pxtorem(4px) 0;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &--button {
      display: inline-block;
      max-width: 43px;
      margin-right: space(8);
      margin-bottom: 0;
      vertical-align: middle;
      font-size: pxtorem(20px);
      font-weight: bold;
      line-height: pxtorem(20px);
      text-decoration: underline;

      &:before {
        content: 'Menu';
        display: block;
      }

      @include for-small-phone {
        font-size: 0.625rem;
        line-height: 0.625rem;
        max-width: 32px;
      }
    }

    i {
      display: inline-block;
      vertical-align: middle;
      margin-left: space(12);
      font-size: pxtorem(22px);
    }

    &.active {
      span:before {
        content: 'Close';
      }

      i:before {
        content: '\F00D';
      }
    }
  }
}

.header__container {
  max-width: 1260px;
  align-items: flex-end !important;

  .active & {
    padding: 0;
  }
}

.header__actions {
  background-color: $pink;
  padding: space(8) 0;
  margin-bottom: space(24);

  @include for-tablet-portrait-down {
    margin-bottom: 0;
  }

  .flex-col {
    @include for-tablet-portrait-down {
      width: auto;
    }
  }
}

.header__content {
  transform: translateY(-16px);

  @include for-tablet-landscape-down {
    width: 100%;

    &--active {
      margin-bottom: 0 !important;
    }
  }

  @include for-desktop-up {
    padding: 0 !important;
  }
}

.nav {
  position: relative;
  display: inline-block;
  padding: space(12) space(16);

  &--primary {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    padding: 0;

    @include for-tablet-landscape-down {
      float: none;
      display: block;
      text-align: left;
    }
  }
}
