@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';
@import '../../styles/fonts.scss';
@import '../../styles/breakpoints.scss';

.service__header {
  position: relative;
  padding-bottom: space(24);
  background: linear-gradient(to bottom, $white 0%, $white 50%, $light-turquoise 50%,$light-turquoise 100%);
  z-index: 1;
  .flex-container {
    max-width: 1260px;

    @include for-tablet-landscape-down {
      padding: 0 !important;
    }
  }

  &__wrapper {
    flex: 0 0 100%;
    position: relative;
    padding: space(32) space(48);
    background-color: $yellow;
    
    @media(min-width: 426px) {
      display: flex;
    }
  
    @include for-tablet-portrait-down {
      padding: space(24) space(32);
    }

    @include for-phone-only {
      padding: space(16) space(16);
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 10;
      bottom: -38px;
      right: 20%;
      width: 0; 
      height: 0; 
      border-left: 38px solid transparent;
      border-right: 38px solid transparent;
      border-top: 38px solid $yellow;
  
      @include for-tablet-portrait-down {
        bottom: -20px;
        right: space(20);
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid $yellow;
      }
    }
  }

  h1 {
    margin-bottom: space(8);
    font-weight: 700;
  }

  button {
    height: auto;
    margin-top: space(16);
    padding: space(8) space(12);
    background-color: transparent;
    font-weight: normal;
  }

  &__desc {
   
    @include for-tablet-landscape-up {
      max-width: 85%;

    }
  }
}

.service__section {
  margin-bottom: space(32) !important;
  width: 100%;
  align-items: stretch;

  &--no-padding {
    @include for-tablet-portrait-up {
      padding: 0 !important;
    }
  }

  &--no-margin {
    margin-bottom: 0 !important;
  }
}

.service__header__logo {
  flex: 0 0 calc(100% - 75%);
  height: 154px;
  margin-right: space(40);
  margin-bottom: space(16);
  border-radius: 20px;
  overflow: hidden;
  max-width: 154px;
  background-color: #ffffff;

  @include for-phone-only {
    height: 120px;
    margin-right: 0;
    max-width: 100%;

    @media(min-width: 426px) {
      & + div {
        padding-left: 1.5rem;
      }
    }
  }

  @media(max-width: 425px) {
      margin-bottom: space(12);
      border-radius: 12px;
      height: 130px;
      max-width: 130px;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.service__header__last-updated {
  @include body-l;
  font-weight: 400;

  span {
    font-family: $font-primary;
    margin-left: space(8);
  }
}

.service__info {
  position: relative;
  padding: space(48) 0 space(64) 0 !important;
  background-color: $light-turquoise;
  color: $black;

  @include for-phone-only {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &::after {
    content: url('../../assets/images/backgrounds/park-trimmed.svg');
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    z-index: 0;
  }

  > .flex-container {
    position: relative;
    z-index: 10;
  }

  p {
    font-size: pxtorem(18px);
  }
}

.service__info--left {
  width: 60%;
}

.service__info--right {
  width: 40%;
}

.service__info__cost {
  &.criteria_card {
    @media(max-width: 860px) {
      margin-top: space(16);
    }
  }
}

.service__info__cost--icon {
  font-size: 36px;
}

.service__info__cost--left {
  text-align: center;
}

.service__info__cost--right {
  margin-left: space(32);
}

.react-player__preview {
  background-color: $white;
}

.react-player__shadow {
  background-color: $pink !important;
}

.service__referral {
  background-color: $yellow;
  padding: space(32) !important;
  position: relative;

  @include for-tablet-landscape-down {
    padding: space(32) space(16) !important;
  }
}

.service__refer-disclaimer {
  padding-top: space(16) !important;

  p {
    text-align: left;
    padding-left: space(8);
  }

  @include for-tablet-portrait-down {
    text-align: center;
  }
}

.service__markdown {
  &--intro {
    border-left: 10px solid $lynch;
    padding: space(16) 0 space(16) space(20);
    

    @media(min-width: 601px) {
      margin-bottom: space(40);
    }

    p {
      @include body-l;
      margin-bottom: 0 !important;
      font-size: pxtorem(22px) !important;
    }

    ul {
      list-style-type: none;
    }

    ul li {
      &:before {
        content: '•';
        margin: 0 pxtorem(4px);
        color: $pink;
      }
    }

    li > p {
      display: inline;
      hyphens: auto;
    }

    li {
      font-family: $font-primary;
    }
  }

  &--description {
    word-break: break-word;

    @media(max-width: 600px) {
      margin-top: space(32);
    }

    li > p {
      display: inline;
      hyphens: auto;
    }

    &--tight {
      @include for-tablet-portrait-up {
        p {
          &:first-of-type {
            margin-top: space(24);
          }
        }
      }
    }
  }

  a {
    font-size: inherit;
  }
}

.service__accordian {
  padding: space(24) space(12);
  width: 100%;    

  button {
    text-align: left;
    margin: 0;
    padding: 0;
    width: 100%;

    h3 {
      display: inline;
    }
  }

  &:nth-of-type(odd) {
    background-color: $white;

    .service__accordian-inner {
      background-color: $white;
    }
  }

  &:nth-of-type(even) {
    background-color: $gray;

    .service__accordian-inner {
      background-color: $white;
    }
  }

  &--no-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.service__button-container {
  justify-content: flex-start !important;
  padding: space(32) 0 space(24) !important;

  @include for-tablet-landscape-down {
    padding: space(32) space(16) space(24) !important;

    svg {
      display: none;
    }
  }

  &--mobile {
    @include for-phone-only {
      margin-bottom: space(16) !important;
      text-align: center;
    }

    .button:first-child {
      margin-right: space(12);
    }
  }

  .button {
    width: 100%;
  }
}

.service__accordian-inner {
  font-size: $font-body-m;
  padding: space(16) !important;

  a {
    font-size: $font-body-m;
  }
}

.service__social-icon {
  margin: 0 space(12);
}

.service__social-icon-container {
  text-align: center;
}

.service__testimonial {
  background-image: url('../../assets/images/backgrounds/service-quote-shape.svg');
  background-size: 100% 100%;
  padding: space(8);

  &--mobile {
    background-image: none;
    background-color: $white;
    border: 1px solid $black;
    padding: space(16) space(24) space(24) !important;

    svg {
      width: auto !important;
      font-size: pxtorem(36px) !important;
    }
  }

  p {
    font-family: $font-primary;
    font-size: pxtorem(30px);
    line-height: pxtorem(32px);
    padding: space(8);
  }

  svg {
    font-size: pxtorem(46px);
    width: auto !important;
    padding-right: space(10);

    @include for-tablet-landscape-down {
      padding-right: space(16);
    }
  }

  @include for-tablet-landscape-up {
    padding: space(32);
  }

  &--header {
    margin-top: space(32);

    @include for-phone-only {
      margin-bottom: 0 !important;
    }
  }
}

.service__criteria {
  padding: 0 !important;
}

.service__media {
  margin: 0 !important;
}

.service__useful-info {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: space(16) !important;
  background-color: $white;

  @media(min-width: 601px) {
    padding: 0 !important;
  }

  @include for-phone-only {
    flex-wrap: wrap;
    margin-bottom: 0 !important;
  }

  &__icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 160px;
    background-color: $pink;

    &::after {
      content: "";
      position: absolute;
      z-index: 10;
      bottom: calc(50% - 24px);
      right: -24px;
      width: 0; 
      height: 0; 
      border-top: 24px solid transparent;
      border-bottom: 24px solid transparent;
      border-left: 24px solid $pink;
  
      @include for-tablet-portrait-down {
        border-top: 24px solid transparent;
      border-bottom: 24px solid transparent;
      border-left: 24px solid $pink;
      }
    }
  }

  &__content {
    flex: 0 1 auto;
    padding: space(24) space(48);

    @include for-phone-only {
      padding: 0;
      margin: 0;
    }
  }

  p {
    margin-top: space(12);
  }

  a {
    font-size: $font-base;
  }

  svg {
    font-size: pxtorem(36px);
  }
}

.service__organisation {
  text-align: center;

  &--logo {
    margin-bottom: space(12) !important;
  }

  &--sidebar {
    background-color: $white;
    padding: space(20) !important;
  }
}

.service__right-column {
  padding: 0 !important;
}

.service__left-column {
  @media(min-width: 861px) {
    padding-right: space(64) !important;
  }

  .service__heading {
    @include for-tablet-landscape-up {
      margin-left: space(24);
    }
  }
}

.service__cost-card {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  width: 100%;

  @media(max-width: 860px) {
    margin-top: space(32);
  }

  &--img {
    padding-right: space(20);
    flex-shrink: 0;

    @media(max-width: 860px) {
      padding-right: space(24);
    }
  }

  &--content {
    flex: 1;
  }
}

.service__video {
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
}

.service__contact-card {
  position: relative;
  padding: space(24) !important;
  background-color: $yellow;

  &::before {
    content: "";
    position: absolute;
    z-index: 10;
    top: -38px;
    right: 20%;
    width: 0; 
    height: 0; 
    border-left: 38px solid transparent;
    border-right: 38px solid transparent;
    border-bottom: 38px solid $yellow;

    @include for-tablet-portrait-down {
      top: -20px;
      right: space(20);
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid $yellow;
    }
  }

  .flex-col {
    margin-bottom: space(24);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--accordian {
    h3 {
      margin-bottom: space(8);
    }

    .service__social-icon-container {
      padding-bottom: 0;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h3 {
    margin-bottom: space(8);
  }

  &--row {
    svg {
      margin-right: space(8);
      color: $black;
    }
  }
}

.service__share-card {  
  @media(max-width: 600px) {
    margin-top: 2rem;
  }

  h3 {
    display: inline;
    margin-right: space(16);
    font-weight: 900;
  }

  a {
    margin: 0 space(8);
  }

  &__social {
    margin-bottom: space(24);
  }
}

.criteria_card--inner {
  width: 100%;

  @media(min-width: 601px) {
    padding: 0 !important;
  }
}

.service__information {
  position: relative;
  background: $white;
  padding: space(32) !important;

  @include for-phone-only {
    margin-top: space(16) !important;
    padding: space(16) !important;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 10;
    bottom: -38px;
    right: space(64);
    width: 0; 
    height: 0; 
    border-left: 38px solid transparent;
    border-right: 38px solid transparent;
    border-top: 38px solid $white;

    @include for-tablet-portrait-down {
      bottom: -20px;
      right: space(20);
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid $white;
    }
  }
}

.service__information, .service__markdown:not(.service__markdown--intro) {
  h2, h3, .service__heading {
    margin-left: 0 !important;
    margin-bottom: space(10);
  }

  h3 {
    @include for-phone-only {
      font-size: $font-body-l;
    }
  }

  p {
    @include body-m;
  }

  p, ul {
    margin-bottom: space(24);
  }

  ul {
    padding-left: space(20);
    list-style-position: outside;

    li {
      @include body-m;

      &:not(:last-child) {
        margin-bottom: space(12);
      }
    }
  } 
}

.service__offerings {
  display: flex;
  align-items: center;
  margin-bottom: space(16) !important;
  font-family: $font-primary;

  @include for-phone-only {
    margin-top: space(8);
    margin-bottom: 0 !important;
  }
}

.service__heading {
  margin-bottom: space(24);

  @include for-phone-only {
    font-size: $font-body-l !important;
  }
}

.service__gallery {
  padding: 0 !important;
  width: 100%;
  margin: 0 !important;
}

.criteria-cards {
  width: 100%;
}

.service__map {
  height: 320px;

  @include for-tablet-portrait-down {
    padding: 0;
  }

  .leaflet-container {
    min-height: 100%;
  }
}

.service {
  &__action-buttons {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.3rem 0.5rem;
    width: 100%;

    & > button {
      flex: 1;
      margin-left: 0.3rem;
      margin-right: 0.3rem;
      min-width: 100%;
      
      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      @media(min-width: 524px) and (max-width: 860px) {
        margin-left: 0.3rem;
        margin-right: 0.3rem;
        min-width: auto;
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }

      @media(min-width: 861px) and (max-width: 900px) {
        font-size: 1rem;
      }
    }
  }
}