@import '../../styles/mixins.scss';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.accordian-icon {
  margin-left: space(8);
  position: absolute;
  right: 25px;
}

.accordian-icon--open {
  transform: rotate(180deg);
}

.accordian-content {
  padding: space(16) 0;
  opacity: 0;

  animation: fadeIn 200ms ease-in-out 0s forwards;

  p {
    margin: 0;
  }

  h4 {
    display: block;
    font-weight: normal;
  }
}

.accordian-container {
  cursor: pointer;
  button {
    background: none;
    border: none;
  }

  h4 {
    font-weight: 700;
  }
}

#accordian-header {
  padding: 0;
  margin: 0;
}
