@import '../../styles/breakpoints.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
@import '../../styles/colors.scss';

.modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background: white;
  overflow: auto;
  outline: none;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
  color: $black;

  .flex-container {
    padding: 0 !important;
  }

  button {
    margin-top: space(32);
  }

  label {
    display: block;
    margin-bottom: space(16);
  }

  .input--container {
    width: 100%;
    margin: 0;
  }

  input {
    width: 100%;
    border: 2px solid $black;
  }

  textarea {
    @include body-m;
    width: 100%;
    border: 2px solid $black;
    resize: none;
    height: pxtorem(260px);
    padding: space(12) !important;
    margin-top: space(12);
  }

  @include for-phone-only {
    max-width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.ReactModal__Overlay {
  z-index: 999;
  background-color: rgba(45, 45, 45, 0.6) !important;
}

.modal__close {
  color: #1f8275;
  cursor: pointer;
  text-align: right;

  svg {
    vertical-align: middle;
  }
}

.modal__question {
  margin: space(16) 0;
}
