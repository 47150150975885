@import '../../../styles/mixins.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/breakpoints.scss';

.sidebox {
  height: 100%;
  padding: space(24) !important;
  border-radius: 20px;
  background: $pink;

  &:nth-child(2) {
    background-color: $turquoise;
  }

  &:nth-child(3) {
    background-color: $yellow;
  }

  h3 {
    color: $black;
  }

  p {
    @include body-s;
    color: $black;
    margin: space(8) 0;
  }

  a {
    @include body-s;
    color: $black;
  }

  &--container {
    @include for-tablet-portrait-down {
      margin-bottom: space(16) !important;
    }
  }
}
