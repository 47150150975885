@import '../../styles/breakpoints.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.home {
  background-color: $light-turquoise;
  background-image: url('../../assets/images/backgrounds/park.svg');
  background-position: -32px 320px;
  background-size: calc(100% + 35px) auto;
  background-repeat: no-repeat;

  @include for-phone-only {
    padding-bottom: space(40);
  }
}