@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
@import '../../styles/colors.scss';

.cms {
  color: $black;
  font-family: $font-primary;

  &--contact-card {
    position: relative;
    padding: space(16);
    background-color: $turquoise;
    text-overflow: ellipsis;
    margin-left: space(16);

    @include for-tablet-portrait-down {
      margin-left: 0;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -24px;
      right: space(32);
      width: 0; 
      height: 0; 
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;
      border-top: 24px solid $turquoise;
    }

    &--row {
      margin-top: space(24);
    }

    &--socials {
      margin-top: space(16);

      .service__social-icon {
        margin-left: 0;
        margin-right: space(16);
      }
    }

    h3 {
      padding: space(8) 0;
    }

    h4 {
      margin-bottom: space(8);
    }

    &--email {
      display: block;
    }

    a {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.cms__header {
  .flex-container {
    max-width: 1260px;
  }

  @include for-tablet-portrait-down {
    margin-top: space(52);

    .flex-col {
      width: 41.66667%;
    }
  }

  @include for-tablet-portrait-down {
    .flex-col {
      width: 100%;
    }
  }
}

.cms__title-card {
  display: block;
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  padding: space(32) space(48);
  background-color: $yellow;

  &--title {
    font-size: pxtorem(32px);
    line-height: pxtorem(32px);

    @include for-phone-only {
      font-size: pxtorem(22px);
      line-height: pxtorem(22px);
    }
  }
}

.cms__content {
  padding: space(32);

  > .flex-container {
    max-width: 1260px;
  }

  h1 {
    @include heading-xl;
  }

  p {
    @include body-m;
    color: $black;
    word-break: break-word;
    margin-bottom: space(16);
    line-height: 1.75rem;
  }

  li p {
    display: inline;
  }

  h1,
  h2 {
    margin-bottom: space(32);
  }

  ul {
    margin-left: space(24);
    margin-bottom: space(24);
  }


  > div {
    width: 100% !important;
  }
}
