@import '../../styles/colors.scss';

.react-select {
 &__value-container {
    padding: 18px 1.25rem !important;
 }   

 &__menu {
   z-index: 999 !important;
 }

 &__multi-value {
   color: #fff !important;
    background-color: $pink !important;
 }

 &__multi-value , &__multi-value__label {
  color: #fff !important;
 }

 &__control {
  border-color: hsl(0, 0%, 60%) !important;
 }
 &__indicator-separator  {
  background-color: hsl(0, 0%, 60%) !important;
 }
 &__indicator  {
  color: hsl(0, 0%, 60%) !important;
 }
}