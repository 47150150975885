@import '../../../../styles/fonts.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/breakpoints.scss';
@import '../../../../styles/mixins.scss';

.view-filter__search-bar {
  button {
    height: 2.25rem;

    &:first-of-type {
      margin-right: 5px;
    }

    @include for-phone-only {
      font-size: pxtorem(14px);
      width: pxtorem(75px);
      padding: 0 space(8);
      height: pxtorem(35px);
    }
  }
}

.view-filter--right-align {
  @include for-desktop-up {
    text-align: right;
  }

  @include for-tablet-portrait-up {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &--float-right {
      text-align: right;
    }
  }
}

.view-filter__results-bar {
  display: flex;
  align-items: center;
}

.view-filter--header {
  @include heading-m;

  @include for-phone-only {
    width: 100%;
    margin-bottom: space(8);
  }
}