@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/breakpoints.scss';
@import '../../styles/fonts.scss';

.search-result-card {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: space(20);
  border-radius: 20px;
  border: 2px solid transparent;
  background-color: $white;
  color: $black;
  cursor: pointer;

  &.is-active,
  &:hover {
    border-color: $black;
  }
}

.search-result-card--full-width {
  width: 100%;
  margin: 0;
}

.search-result-card__title {
  flex: 1;
  padding-right: space(16);
}

.search-result-card__organisation {
  @include heading-s-light;
  font-weight: normal;
  margin-top: space(8);

  @include for-phone-only {
    margin: space(8) 0 space(16) 0;
  }
}

.search-result-card__logo {
  width: pxtorem(65px);
  height: pxtorem(65px);
  background-color: $white;
  border-radius: 4px;
  border: 1px solid $gray;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.search-result-card__top-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: space(12);
}

.search-result-card__tags {
  display: flex;
  align-items: center;
  width: 100%;
}

.search-result-card__tag {
  margin-right: space(16);
  margin-bottom: space(16);
  padding: space(8);
  @include body-xs;
  line-height: 0.875rem;
  background-color: $yellow;
  color: $black;
  border-radius: 4px;
}

.search-result-card__tag--icon {
  margin-right: space(8);
}

.search-result-card__tag--cost--free {
  @include fontSize(7px);
}

.search-result-card__location {
  display: flex;
  margin-bottom: space(12);

  h4 {
    margin-left: space(8);
    font-size: $font-body-xs;
  }
}

.search-result-card__location-list {
  margin-left: space(8);

  button {
    cursor: pointer;
    @include link-medium;
    border: none;
    background: none;
  }

  h4 {
    display: inline;
    margin-left: 0;
  }
}

.search-result-card__intro {
  align-self: flex-start;
  margin-bottom: space(12);
}

.search-result-card__footer {
  display: block;
  width: 100%;
  align-self: flex-end;

  a {
    display: flex;
    align-items: center;
    font-size: $font-body-m;
    text-decoration: none;
  }

  svg {
    font-size: pxtorem(24px);
    margin-left: space(12);
  }
}
