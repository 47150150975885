@import '../../styles/mixins.scss';

.loading {
  opacity: 1;
  animation: fadeinout 4s infinite forwards;
  padding: space(64) 0;
}

@keyframes fadeinout {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
