@import '../../styles/breakpoints.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.category-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 1030px;
  max-width: 100%;
  padding: 0 space(56);

  @media(max-width: 992px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 0;
  }

  @include for-phone-only {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  &__heading {
    grid-column: span 1;
    display: inline-block;
    padding: space(16);
    background-color: $turquoise;
    border-radius: 20px;
    margin-bottom: space(24);

    @include for-tablet-landscape-down {
      grid-column: span 2;
    }
  }

  &__items {
    grid-column: span 3;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 26px;

    @media(max-width: 992px) {
      grid-column: span 2;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include for-phone-only {
      grid-column: span 1;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}