@import '../../../styles/mixins.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/breakpoints.scss';

.related-services {
  background-color: $yellow;
  font-family: $font-primary;
  padding: space(56) 0 space(104) 0;
  text-align: center;
  color: $black;

  @include for-tablet-landscape-down {
    padding: space(32) 0 space(48);
  }

  .button {
    margin: space(16) 0;
  }

  &__container {
    display: grid;
    gap: space(32);
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: space(24) !important;

    @include for-tablet-portrait-down {
      gap: space(24);
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin-top: space(24) !important;
    }

    @include for-phone-only {
      gap: 0;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}
