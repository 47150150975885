@import '../../../styles/mixins.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';

.criteria_card {
  width: 100%;
  padding: space(20);
  background-color: $white;
  position: relative;

  &:nth-of-type(even) {
    background-color: $gray;
  }

  @include for-tablet-portrait-up { 
    display: flex;
  }
}

.criteria_card-img {
  min-width: 86px;
  text-align: center;

  svg {
    width: pxtorem(36px);
  }
}

.criteria_card-title {
  font-size: pxtorem(14px) !important;
  font-weight: 900;

  @include for-tablet-landscape-up {
    margin-top: space(8);
  }
}

.criteria_card-toggle {
  text-align: right;
  padding-right: space(8);

  button {
    cursor: pointer;
  }
}

.criteria_card-content {
  
  padding-left: space(16);
  flex: 1;

  @include for-tablet-portrait-up {
    padding-left: space(20);
  }

  p {
    font-size: pxtorem(18px);

    @include for-tablet-portrait-down {
      font-size: pxtorem(16px);
    }
  }

}

.service__criteria {
  @include for-tablet-portrait-down {
    padding: 0 space(16) !important;
  }
}
