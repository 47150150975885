@import '../../../../styles/colors.scss';
@import '../../../../styles/fonts.scss';
@import '../../../../styles/mixins.scss';

.related-services-card {
  padding: space(20);
  background-color: $white;
  border-radius: 20px;
  text-align: left;

  @include for-tablet-portrait-down {
    width: 100%;
    margin-bottom: space(16);
    padding: sapce(24) space(16) !important;
  }

  &__info {
    margin-top: space(24);
    color: $lynch;
    font-size: $font-body-xs;
    line-height: 17px;
  }

  &__logo {
    flex: 0 0 auto;
    width: pxtorem(60px);
    height: pxtorem(60px);
    border: 1px solid $gray;
    background-color: $white;

    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    img {
      width: 100%;
    }
  }

  .link {
    margin-top: space(12);
  }
}