@import '../../styles/variables.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';

.input {
  @include body-m;
  width: 100%;
  border: none;
  padding: space(20);
  color: $black;
  border-radius: 4px;
  font-size: pxtorem(16px);
  line-height: 1rem;
  transition: box-shadow $formControlTransitionDuration ease-in-out;

  &:focus, &:active {
    outline: 0;
    box-shadow: 0 0 0pt $formControlOutlineWidth $blue;
  }

  &--container {
    display: inline-block;
    margin: space(24) 0;
    @include for-phone-only {
      display: block;
    }
  }

  &--error {
    display: block;
    border-left: 2px solid $pink;
    padding-left: space(16);
  }

  &__autocomplete {
    @include for-mobile-up {
      padding-right: 6rem;
    }
    
    &:disabled {
      background-color: #f3f3f3;
      border: 1px solid #fff;
    }
  }
}

.input--full-width {
  width: 100%;
}

.input__error-message {
  display: none;
  position: relative;

  &--show {
    display: inline-block;
    color: $pink;
    margin-top: space(8);
  }
}

input:invalid {
  &:after {
    content: 'I';
    position: absolute;
    top: 0;
  }
}
