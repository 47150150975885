@import '../../styles/fonts.scss';
@import '../../styles/colors.scss';

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.link--large {
  @include link-large;
}

.link--medium {
  @include link-medium;
}

.link__inline {
  display: inline;
}

.link__icon--right {
  margin-left: space(12);
}

.link__icon--left {
  margin-right: space(16);
}

.link__header {
  @include link-header;
  position: relative;
  padding: space(12) space(16);
  margin-left: space(24);
  font-size: pxtorem(20px);
  text-decoration: none;
  color: $black;
  white-space: nowrap;

  &:last-of-type {
    margin-right: 0;
  }

  @include for-tablet-landscape-down {
    margin: 0;
    padding: space(12) 0;
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: -4px;
    left: 0;
    background-color: $black;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    transform: translateY(8px);
  }

  &--active,
  &:hover {
    text-decoration: none !important;

    &::after {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
