@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/breakpoints.scss';

.leaflet-container {
  min-height: calc(100vh - 160px);
  width: 100%;
  border-radius: 20px;

  a {
    color: inherit;
  }
}

.map__map-container {
  position: relative;

  @include for-tablet-portrait-down {
    margin-bottom: space(32) !important;
  }
}

.map__key--heading {
  font-weight: 700;
  margin-bottom: space(8);
}

.map__key--container {
  margin-top: space(16);
  padding: space(16) space(16) 0;
  border-radius: 20px;
  background-color: $white;
}

.map__key--description {
  margin-right: space(24);
  color: $black;
  font-family: $font-primary;
  font-size: pxtorem(14px);
}

.map__key {
  display: flex;
}

.map__key-icon {
  margin-right: space(8);
  height: 14px !important;
  width: 14px !important;
  font-size: pxtorem(14px);
}

.map__key-icon--group {
  color: $turquoise;
}

.map__key-icon--service {
  color: $black;
}

.map__key-icon--activity {
  color: $pink;
}

.map__key-icon--club {
  color: $lynch;
}

.map__key--description {
  margin-bottom: space(16);
}

.leaflet-popup-content {
  margin: 0;
}

.map__results-container {
  position: relative;
  height: calc(100vh - 60px);
  overflow-y: scroll;

  @media(min-width: 861px) {
    padding-right: space(24);
  }

  @media(min-width: 769px) and (max-width: 860px) {
    margin-top: space(32);
  }

  @include for-tablet-portrait-down {
    height: unset;
    padding-right: 0;
  }

  .results__container {
    margin: 0;
    padding: 0 !important;
    overflow: hidden;
    grid-template-columns: 1fr;

    @media(max-width: 860px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include for-phone-only {
      grid-template-columns: 1fr;
    }
  }

  .search-result-card {
    width: 100%;
  }
}
