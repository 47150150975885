@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';
@import '../../styles/fonts.scss';
@import '../../styles/breakpoints.scss';
@import '../../styles/variables.scss';

.events__results {
  position: relative;
  background: linear-gradient(to bottom, $white 0%, $white 30%, $light-turquoise 270px,$light-turquoise 100%);
  .date-range-icon-wrapper > svg  {
    position: absolute;
    right: 3rem;
    top: 2rem;
  }
  .select {
    max-width: unset;
  }
}

.events__list {
  position: relative;
  z-index: 0;
  padding-top: space(40);
  padding-bottom: space(40);

  &::after {
    content: url('../../assets/images/backgrounds/park-trimmed.svg');
    position: absolute;
    bottom: -180px;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    z-index: -1;
  }
}

.pagination {
  display: flex;
  padding-bottom: pxtorem(40px);

  .arrow {
    padding: pxtorem(10px);
    font-size: pxtorem(20px);
    background: $white;
    align-items: center;
    span {
      padding: pxtorem(2px) pxtorem(10px);
    }
    &:hover {
      filter: brightness(85%);
      cursor: pointer;
    }
  }

  .arrow--left {
    margin-left: auto;
  }
  
  .arrow--right {
    margin-right: auto;
    flex-direction: row-reverse;
    display: flex;
  }
}

.pagination__pages {
  display: inline-flex;
  grid-column-gap: 5px;
  justify-content: center;
  flex-grow: 1;
  > div {
    padding: pxtorem(10px);
    font-size: pxtorem(20px);
    background: $yellow;
    height: 54px;
    line-height: 35px;
    width: 40px;
    text-align: center;
    &:hover {
      filter: brightness(85%);
      cursor: pointer;
    }
  }
}

.events__filter {
  background: $turquoise;
  width: 100%;
  padding: pxtorem(45px);
  position: relative;
  z-index: 2;
  @include for-phone-only {
    padding: pxtorem(16px);
  }
}

.events__filters--remove {
  color: $black;
  font-weight: 700;
  cursor: pointer;
  margin-top: 2rem;
  @include for-phone-only {
    margin-top: 1rem;
  }
}

.events__filter__heading {
  font-size: pxtorem(30px);
  margin-bottom: 1rem;
}

.events__filter__filters {
  display: flex;
  margin-bottom: 1rem;
  gap: 4rem;
  > div:nth-child(1) { flex-grow: 2; }
  > div:nth-child(2) { flex-grow: 1; }
  > div:nth-child(3) { flex-grow: 1; }
}

.flex--center--space-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.events__filters--secondary {
  border-radius: 8px;
  @include for-tablet-landscape-down {
    margin-top: 2rem;
  }
  @include for-phone-only {
    margin-top: 0;
  }
  .events__filters--secondary-text {
    h3 {
      margin-bottom: 1rem;
    }
    margin-bottom: 1rem;
    @include for-tablet-landscape-down {
      max-width: 68%;
    }
    @include for-phone-only {
      max-width: 100%;
    }
  }
  .inner-flex {
    display: flex;
    justify-content: space-between;
    @include for-phone-only {
      display: block;
    }
  }
  .checkbox-wrapper {
    margin-top: pxtorem(25px);
  }
  .checkbox {
    margin-bottom: pxtorem(10px);
  }
}

.flex--align--start {
  display: flex;
  align-self: flex-start;
}

.date-picker-outer-wrapper {
  > label {
    margin-right: 1.25rem;
    font-size: pxtorem(22px);
    font-weight: 700;
    font-family: "Nunito Sans", sans-serif;
    @include for-phone-only {
      margin-bottom: 1rem;
      display: block;
    }
  }
}

.date-range-wrapper {
  margin-bottom: 1rem;
  .button__alt--small.active {
    background: black;
    color: white;
    box-shadow: none;
  }
}

.date-range-icon-wrapper {
  align-self: center;
  opacity: 0.4;
  @include for-tablet-landscape-down {
    display: none;
  }
}

.react-datepicker__input-container,
.react-datepicker-wrapper {
  width: 150px;
  @include for-phone-only {
    width: 100%;
  }
}

.react-datepicker__input-container input {
  display: block;
  font-family: "Nunito Sans", sans-serif;
  border: none;
  padding: space(20);
  color: $black;
  border-radius: 4px;
  font-size: pxtorem(18px);
  line-height: 1rem;
  transition: box-shadow $formControlTransitionDuration ease-in-out;
  width: inherit;

  &:focus, &:active {
    outline: 0;
    box-shadow: 0 0 0pt $formControlOutlineWidth $blue;
  }

  @include for-phone-only {
    width: 100%;
  }
}

.events__list--none {
  padding: 6.5rem;
  @include for-tablet-landscape-down {
    padding: inherit;
  }
  h2, p {
    margin-bottom: 2rem;
  }
}

.p--heading {
  @include heading-l;
  max-width: 800px;
  padding: 0 0 0 4rem;
  font-weight: normal;
  box-sizing: content-box;
  margin-bottom: 6rem;
  @include for-tablet-portrait-down {
    padding: 0;
    margin-bottom: 2rem;
  }
}

.events__results__raised {
  position: relative;
  z-index: 2;
}