@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/breakpoints.scss';
@import '../../styles/fonts.scss';

.personas {
  max-width: 1030px;
  margin: auto;

  @include for-tablet-portrait-up {
    padding: 0 space(40) space(120);
  }

  &__intro {
    padding: space(32) space(24);

    @include for-tablet-portrait-up {
      padding: 0 space(24);
    }
  }

  .flex-container {
    max-width: 1030px;
  }
}

.personas__intro {
  max-width: 1030px;
  margin: 0 auto;
}

.personas__heading {
  margin-bottom: space(8);
}

.personas__list {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  max-width: 1030px;

  @include for-tablet-portrait-up {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin: space(48) auto 0;

    > .card:nth-child(1) {
      grid-column: 1 / span 2;
    }
  
    > .card:nth-child(3) {
      grid-column: 3 ;
      grid-row: 1 / span 2;
      flex-direction: column;
    }
  
    > .card:nth-child(2) {
      grid-column: 1 / span 2;
      flex-direction: row-reverse;
    }
  }
}
