@import './mixins.scss';
@import './breakpoints.scss';

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700;900&display=swap');

$font-primary: 'Nunito Sans', sans-serif;

$font-base: pxtorem(16px);

$font-heading-xl: pxtorem(48px);
$font-heading-mobile-xl: pxtorem(32px);

$font-heading-l: pxtorem(34px);
$font-heading-mobile-l: pxtorem(24px);

$font-heading-m: pxtorem(24px);
$font-heading-mobile-m: pxtorem(20px);

$font-heading-s: pxtorem(18px);

$font-body-l: pxtorem(20px);
$font-body-mobile-l: $font-base;

$font-body-m: pxtorem(18px);
$font-body-mobile-m: pxtorem(16px);
$font-body-mobile-tertiary: $font-base;

$font-body-s: $font-base;

$font-body-xs: pxtorem(14px);

$font-body-alt: pxtorem(30px);
$font-body-mobile-alt: pxtorem(22px);

$font-breadcrumb: $font-base;
$font-breadcrumb-mobile: pxtorem(10px);

$font-link-medium: pxtorem(20px);

/* Headings */
@mixin heading-xl {
  font-size: $font-heading-xl;
  font-family: $font-primary;
  font-weight: 800;
  line-height: 3.5rem;

  @include for-phone-only {
    font-size: $font-heading-mobile-xl;
    line-height: 2.5rem;
  }
}

@mixin heading-l {
  font-size: $font-heading-l;
  font-family: $font-primary;
  font-weight: 700;
  line-height: 2.5rem;

  @include for-phone-only {
    font-size: $font-heading-mobile-l;
    line-height: 2rem;
  }
}

@mixin heading-m {
  font-size: $font-heading-m;
  font-family: $font-primary;
  font-weight: 700;
  line-height: 2rem;

  @include for-phone-only {
    font-size: $font-heading-mobile-m;
    line-height: 1.75rem;
  }
}

@mixin heading-s {
  font-size: $font-heading-s;
  font-family: $font-primary;
  font-weight: 400;
  line-height: 1.22;
}

@mixin heading-s-regular {
  font-family: $font-primary;
  font-weight: 400;

  @include for-phone-only {
    font-size: $font-heading-mobile-s;
    line-height: 1.2;
  }
}

@mixin heading-s-light {
  font-size: $font-heading-s;
  font-family: $font-primary;
  font-weight: 300;
  line-height: 1.22;
}

/* Body */

@mixin body-l {
  font-size: $font-body-l;
  font-family: $font-primary;
  font-weight: 700;
  line-height: 1.25;

  @include for-phone-only {
    font-size: $font-body-mobile-l;
    line-height: 1.38;
  }
}

@mixin body-m {
  font-size: $font-body-m;
  font-family: $font-primary;
  line-height: 1.5rem;

  @include for-phone-only {
    font-size: $font-body-mobile-m;
    line-height: 1.3;
  }
}

@mixin body-m-secondary {
  font-size: $font-body-m;
  font-family: $font-primary;
  line-height: 1.35;

  @include for-phone-only {
    font-size: $font-body-mobile-m;
    line-height: 1.15;
  }
}

@mixin body-m-tertiary {
  font-size: $font-body-m;
  font-family: $font-primary;
  line-height: 1.35;

  @include for-phone-only {
    font-size: $font-body-mobile-tertiary;
    line-height: 1.38;
  }
}

@mixin body-s {
  font-size: $font-body-s;
  font-family: $font-primary;
  line-height: 1.25;
}

@mixin body-s-secondary {
  font-size: $font-body-s;
  font-family: $font-primary;
  line-height: 1.13;
}

@mixin body-s-tertiary {
  font-size: $font-body-xs;
  font-family: $font-primary;
  line-height: 1.15;
}

@mixin body-xs {
  font-size: $font-body-xs;
  font-family: $font-primary;
  line-height: 1.15;
}

@mixin body-alt {
  font-size: $font-body-alt;
  font-family: $font-primary;
  line-height: 1;

  @include for-phone-only {
    font-size: $font-body-mobile-alt;
  }
}

@mixin link-header {
  font-size: $font-body-m;
  font-family: $font-primary;
  font-weight: 700;
}

@mixin link-large {
  font-size: $font-heading-m;
  font-family: $font-primary;
  display: block;
}

@mixin link-medium {
  font-size: $font-link-medium;
  font-family: $font-primary;
  line-height: $font-link-medium;
}

@mixin breadcrumb {
  font-size: $font-breadcrumb;
  font-family: $font-primary;
  line-height: 1.13;

  @include for-phone-only {
    font-size: $font-breadcrumb-mobile;
    line-height: 1.2;
  }
}
