@import '../../../styles/colors.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/breakpoints.scss';

@mixin image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@mixin overlay-icon {
  content: '\f302';
  font-family: 'Font Awesome 5 Brands', 'Font Awesome 5 Free';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: pxtorem(20px);
}

@mixin overlay {
  background-image: linear-gradient(to bottom right, #46c0b0, #46c0b0);
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.8;
}

.gallery-card {
  padding: 0 !important;
  margin-bottom: space(32) !important;

  @include for-tablet-portrait-down {
    margin-bottom: space(24) !important;
  }

  @include for-phone-only {
    margin-bottom: space(8) !important;
  }

  .flex-container {
    padding: 0;
  }

  &--one-container {
    position: relative;
  }

  &--image {
    position: relative;

    @include for-phone-only {
      display: flex;
    }

    &:last-of-type {
      &:before {
        @include overlay();
        text-align: center;
        vertical-align: middle;
      }

      &:after {
        @include overlay-icon();
      }
    }

    .gallery-card--one-container & {
      &:before, &:after {
        display: none !important;
      }
    }

    &--container {

      &:not(.gallery-card--one-container) {
        height: 180px;
        @include for-phone-only {
          height: 150px;
        }
      }
  
    }

    &--small {
      @include for-phone-only {
        height: pxtorem(70px);
      }
    }

    img {
      @include image;
    }
  }

  &--view {
    background-color: $white;
    text-align: center;
    padding: space(16);
    cursor: pointer;

    p {
      font-family: $font-primary;
      font-weight: 700;

      &:hover {
        text-decoration: underline;
      }

      svg {
        margin-right: space(8);
      }
    }
  }
}
