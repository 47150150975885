@import '../../styles/breakpoints.scss';
@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';
@import '../../styles/colors.scss';
@import '../../styles/fonts.scss';

.results {
  position: relative;
  background: linear-gradient(to bottom, $white 0%, $white 30%, $light-turquoise 270px,$light-turquoise 100%);
}

.results__list {
  position: relative;
  z-index: 0;
  padding-top: space(40);
  padding-bottom: space(40);

  &::after {
    content: url('../../assets/images/backgrounds/park-trimmed.svg');
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    z-index: -1;
  }
}

.results__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: space(32);
  max-width: 1260px;
  padding: 0 space(40);
  margin: 0 auto;
  position: relative;
  z-index: 10;

  @media (max-width: 860px) {
    padding: 0 space(24);
  }

  @media (max-width: 600px) {
    padding: 0 space(16);
  }

  @include for-tablet-landscape-down {
    grid-template-columns: repeat(2, 1fr);
  }

  @include for-phone-only {
    grid-template-columns: 1fr;
  }
}

.results__container--no-results {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;


  &__suggestions {
    background-color: #fff;
    padding: 3rem 2rem 2rem;
    margin-bottom: space(64);
    width: 100%;
  }

  &__suggestions, &__categories {
    h2 {
      margin-bottom: space(24)
    }
  }

  &__categories {
    max-width: 100%;
    display: block;

    @include for-desktop-up {
      max-width: 1030px;
    }
  }

  .category-list {
    padding: 0;
  }
}

.results__container__category-list {
  margin-top: space(32);

  h2 {
    margin-bottom: space(16);
  }
}

.results__container-count {
  display: flex;
}

.results__search-box {
  position: relative;
  z-index: 10;

  @include for-phone-only {
    h1 {
      font-size: $font-body-l;
    }
  }

  > .flex-container {
    position: relative;
    max-width: 1260px;
  }
}

.results__info {
  position: relative;
  z-index: 1;
}

.results__info__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: space(12) space(16) space(12) space(24);
  background-color: $white;

  @include for-tablet-portrait-down {
    flex-wrap: wrap;
    padding: space(12) space(16);
  }
}

.results__count {
  @include for-tablet-portrait-down {
    flex: 0 0 100%;
    margin-bottom: space(8);
  }

  @include for-tablet-portrait-up {
    flex: 0 0 50%;
  }

  p {
    font-size: pxtorem(20px);
    font-weight: 700;
  }
}

.results__search-filter {
  display: flex;
  align-items: center;
  justify-content: center;

  .input--container {
    width: auto;
  }
}

.results__search-filter-location {
  width: auto;
}

.results__search-filter-location--label {
  font-size: pxtorem(20px);
  font-weight: 700;
}

/* PAGINATION */
.pagination {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: space(64);
  list-style: none;

  @include for-phone-only {
    margin-top: space(40);
    padding-bottom: space(64);
  }
}

.pagination__item {
  margin: 0;
  background: $yellow;

  &:nth-child(2) {
    padding-left: space(16);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:nth-last-child(2) {
    padding-right: space(16);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &.disabled {
    .pagination__link {
      color: $lynch;
    }
  }
}

.pagination__link {
  display: block;
  padding: space(16) space(8);
  font-size: pxtorem(20px);
  text-decoration: none;

  &:hover,
  &--active {
    text-decoration: underline;
  }

  @include for-phone-only {
    font-size: pxtorem(16px);
  }
}

.pagination__nav-prev {
  margin-right: auto;
  padding: 0 space(8);
  border-radius: 4px;
  background: $white;

  svg {
    margin-right: space(16);

    @include for-phone-only {
      margin-right: space(8);
    }
  }

  @include for-phone-only {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.pagination__nav-next {
  margin-left: auto;
  padding: 0 space(8);
  border-radius: 4px;
  background: $white;

  svg {
    margin-left: space(16);

    @include for-phone-only {
      margin-left: space(8);
    }
  }

  @include for-phone-only {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.results__filters {
  width: 100%;
  position: relative;

  &--primary {
    display: flex;
    align-items: center;
    margin: 0 -0.8rem;
    flex-wrap: wrap;

    & > div {
      padding: 0 0.8rem;
      margin-bottom: space(32);

      @media(max-width: 540px) {
        margin-bottom: 1.5rem;

        &:first-of-type, &:nth-child(2) {
          width: 100%;
          .input {
            width: 100%;
          }
        }
      }

      @media(min-width: 541px) and (max-width: 920px) {
        &:first-of-type {
          flex: 1 1 50%;

          .input--container, .results__search-box-keyword {
            width: 100%;
          }
        }
      }
      
    }

    &__label {
      margin-right: space(16);
      font-weight: 700;

      @include for-tablet-portrait-up {
        margin-right: space(32);
        font-size: pxtorem(20px);
      }
    }

    .checkbox input[type='checkbox'] + label {
      font-size: pxtorem(18px);

      @include for-tablet-portrait-up {
        font-size: pxtorem(16px);
      }
    }
  }

  &--secondary {
    margin: 0;
    background-color: #ffffff;
    padding: 0.3rem 0.3rem 1rem;
    border-radius: $border-radius-sm;

    h3 {
      margin-bottom: space(10);
      @include for-tablet-portrait-up {
        margin-bottom: 0;
      }
    }
  }

  &--remove {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 1rem;
  }

  &--group {
    margin: 1rem -2rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &__item {
      width: 100%;
      padding: 0 2rem;
      margin-bottom: 1.5rem;
      

      @include for-mobile-up {
        width: 50%;
        margin-bottom: 2rem;
      }

      @media(min-width: 425px) and (max-width: 575px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      @media(min-width: 1024px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      label {
        font-size: 1rem;
        font-weight: 700;
        display: block;
        margin-bottom: 0.5rem;

        @media(min-width: 425px) and (max-width: 575px) {
          margin-bottom: 0;
        }
      }

      .autocomplete__wrapper, select {
        max-width: 100%;
        width: 100%;

        @media(min-width: 1024px) {
          max-width: 260px;
        }

        @include for-desktop-up {
          max-width: 320px;
        }

        @media(min-width: 425px) and (max-width: 575px) {
          max-width: 63%;
        }
      }
    }
  }

  .input--container {
    margin: 0;

    @include for-phone-only {
      width: 100%;
    }
  }

  .button {
    display: flex;
    align-items: center;
    height: 2.875rem;

    @include for-phone-only{
      margin-left: 0;
    }
  }

  .flex-col {
    @include for-phone-only {
      margin-bottom: space(16);
    }
  }

  > .flex-container {
    @include for-tablet-portrait-down {
      flex-direction: row;
    }
  }

  .results__search-box-keyword {
    width: auto;
  }
}

.results__filters__heading {
  @include heading-m;
  margin-bottom: space(24);
}

.results__filters__checkboxes {
  margin-top: space(16);

  @include for-phone-only {
    margin-top: 0;
  }

  .checkbox {
    margin-right: space(20);
  }
}

.results__view-filter {
  display: flex;
  align-items: center;

  @include for-phone-only {
    flex-wrap: wrap;
  }

  .button {
    height: 44px;
    padding: space(8) space(12);
    margin-left: space(24);
    font-weight: 400;
    background-color: $gray;
    color: $black;

    &--light {
      background-color: $white;
    }

    @include for-phone-only {
      height: 40px;
      padding: space(8) space(12);
      margin-left: 0;
      margin-right: space(16);
      font-size: pxtorem(16px); 
    }
  }
}

.results__overview__filters--heading {
  display: block;
  margin-bottom: space(8);
  font-weight: normal;
  font-family: $font-primary;
  font-size: $font-heading-s;
  line-height: $font-heading-s;
}

.results__filter-bar {
  align-items: center;
  max-width: 1260px;

  @include for-phone-only {
    flex-direction: column-reverse;
  }

  @include for-tablet-landscape-down {
    padding: space(32) space(16) !important;
    flex-direction: column-reverse;
  }

  @include for-tablet-landscape-up {
    padding-top: space(48) !important;
    padding-bottom: space(48) !important;
  }
}

.results__sort-by-label {
  @include body-m;
  color: $black;
}

.results__sort-by {
  align-items: center;
  justify-content: flex-end;
}

.results__mobile-edit {
  text-align: right;

  button {
    @include body-s;
  }
}

.results__overview__container {
  padding: 0 !important;

  @include for-tablet-landscape-down {
    padding-left: space(16) !important;
  }

  &--end {
    justify-content: flex-end !important;
  }
}

.results__mobile-edit {
  @include for-phone-only {
    button {
      @include body-s;
    }
  }
}

.results__amend {
  button {
    float: right;
    font-size: pxtorem(22px);
    padding: 0 space(20) !important;
  }

  @include for-phone-only {
    button {
      font-size: pxtorem(14px);
      font-weight: normal;
      padding: 0 space(8);
      height: pxtorem(35px);
    }
  }

  @include for-tablet-portrait-down {
    padding: 0 !important;
    font-size: pxtorem(16px);
  }
}

.results__overview {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: space(40);
  background-color: $yellow;
  color: $black;

  @include for-tablet-portrait-down {
    padding: space(24);
  }

  @include for-phone-only {
    padding: space(16);
  }

  &--category {
    .results__filters {
      width: auto;
    }
  }

  &--intro {
    margin: space(10) 0;

    @include for-tablet-landscape-up {
      padding-right: space(40);
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -38px;
    right: 37.5%;
    width: 0; 
    height: 0; 
    border-left: 38px solid transparent;
    border-right: 38px solid transparent;
    border-top: 38px solid $yellow;

    @include for-tablet-portrait-down {
      bottom: -20px;
      right: space(20);
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid $yellow;
    }
  }
}

.results__overview__content {
  display: flex;
  width: 100%;
  margin-bottom: space(24);
  align-items: center;
}

.results__overview__image {
  flex: 0 0 auto;
  margin-right: space(24);
  width: 100px;
  height: 100px;
  background-color: $gray;
  border-radius: 20px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.results__overview__info {
  padding: space(16) 0;
  box-sizing: border-box;
}

.results__heading {
  padding: 0 space(64);
  margin-bottom: space(32);

  @include for-tablet-portrait-down {
    padding: 0;
    margin-bottom: space(16);
  }
}

.results__overview__edit-toggle {
  color: $turquoise;
  font-size: pxtorem(16px);
  border-bottom: 2px solid $turquoise;
}

.results__overview__edit-input {
  margin-top: pxtorem(18px);
}

.results__overview__edit-filters {
  margin-top: pxtorem(18px) !important;

  @include for-tablet-portrait-up {
    padding: 0 !important;
  }
}

.results__overview__edit-submit {
  padding-top: space(64);
  text-align: center;

  @include for-small-phone {
    padding-top: 0;
  }
}

.results__overview__edit-checkbox {
  label {
    font-size: pxtorem(19px) !important;
  }
}

.results__sort-by-container {
  @include for-tablet-landscape-up {
    text-align: right;
  }

  @include for-small-phone {
    margin-top: space(8);
  }
}

.results__category-sidebar {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: space(32);
  max-width: 1260px;
  padding: 0 space(40);
  margin: 0 auto space(32);
  position: relative;
  z-index: 10;

  @include for-tablet-portrait-down {
    padding: 0 space(24);
  }

  @include for-phone-only {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: space(16);
    padding: 0 space(16);
  }
}

.info-cards {
  margin: 2rem auto 0;
  list-style: none;
  padding: 0;

  @include for-small-mobile-up {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: space(16);
  }
  @include for-tablet-portrait-up {
    max-width: 768px;
    grid-column-gap: space(24);
  }

  & > li {
    margin: 0 0 space(16);
    padding: space(16);
    border-radius: $border-radius-sm;
    background: $turquoise;
    text-align: left;

    @include for-tablet-portrait-up {
      margin: 0 0 space(24);
      padding: space(24);
    }

    strong {
      display: inline-block;
    }

    
  }
}