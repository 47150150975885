@mixin fontSize($pixels) {
  $rem: pxtorem($pixels);

  /* Output rems and px as a fallback */
  font-size: $pixels;
  font-size: $rem;
}

@function pxtorem($pixels) {
  /* Convert px to rem using base font of 16px */
  @return $pixels / 16px * 1rem;
}

@function space($pixels) {
  /* Map px to rem values */
  $spaceamounts: (
    8: pxtorem(8px),
    10: pxtorem(10px),
    12: pxtorem(12px),
    16: pxtorem(16px),
    20: pxtorem(20px),
    24: pxtorem(24px),
    32: pxtorem(32px),
    40: pxtorem(40px),
    48: pxtorem(48px),
    56: pxtorem(56px),
    64: pxtorem(64px),
    72: pxtorem(72px),
    80: pxtorem(80px),
    88: pxtorem(88px),
    96: pxtorem(96px),
    104: pxtorem(104px),
    112: pxtorem(112px),
    120: pxtorem(120px),
  );

  @return map-get($spaceamounts, $pixels);
}
