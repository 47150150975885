@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';
@import '../../styles/breakpoints.scss';

.breadcrumb {
  position: relative;
  z-index: 10;
  
  > .flex-container {
    max-width: 1260px;
  }

  &--active {
    color: $black !important;
  }

  ul > li:first-child {
    margin-left: 0 !important;
  }

  ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    white-space: nowrap;
    overflow: hidden;
    padding: space(20) space(64);
    text-overflow: ellipsis;

    @include for-tablet-portrait-down {
      padding: space(16) 0;
    }

    svg {
      font-size: pxtorem(20px);

      @include for-phone-only {
        font-size: pxtorem(16px);
      }
    }
  }

  li {
    display: inline;
    font-size: pxtorem(20px) !important;
    margin: 0;

    &:first-child {
      margin-left: 0;
    }

    @include for-phone-only {
      font-size: pxtorem(16px) !important;
    }

    a {
      font-size: pxtorem(20px) !important;
      text-decoration: none;

      @include for-phone-only {
        font-size: pxtorem(16px) !important;
      }
    }
  }

  svg {
    margin-left: space(12);
    margin-right: space(12);
    font-size: pxtorem(16px) !important;

    @include for-phone-only {
      margin-left: space(8);
      margin-right: space(8);
      font-size: pxtorem(14px) !important;
    }
  }
}
