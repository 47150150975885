@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
@import '../../styles/breakpoints.scss';

@mixin greenContainer {
  background-color: $turquoise;
  padding: space(24) !important;

  @include for-tablet-landscape-down {
    padding: space(12) space(24) !important;
  }
}

.referral {
  padding: space(40) 0;
  color: $black;

  &--container {
    align-items: center !important;
    height: 100%;

    &--flex-end {
      align-items: flex-end !important;
    }

    padding: 0 !important;
    width: 100%;
  }

  &--right-column {
    margin-bottom: space(24) !important;
  }

  &--next-step {
    margin: space(20) 0 !important;
  }

  &--mobile-connect {
    @include greenContainer;

    @include for-tablet-portrait-down {
      margin-top: space(32);
    }

    @include for-phone-only {
      margin-top: 0;
    }

    &--name {
      font-family: $font-primary;
    }
  }

  &--connect {
    @include greenContainer;

    &--logo {
      width: pxtorem(65px);
      height: pxtorem(65px);

      &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }

      img {
        width: 100%;
      }
    }
  }

  &--back {
    line-height: 26px;
    padding-left: space(16) !important;
    text-decoration: underline;
    cursor: pointer;

    svg {
      margin-right: space(12);
    }
  }

  &--step {
    margin: space(20) 0;
    color: $black;
  }

  &--form-time {
    @include greenContainer;
    margin-top: space(20);

    svg {
      font-size: $font-base;
      margin-right: space(20) !important;
    }

    svg {
      font-size: pxtorem(29px);

      @include for-tablet-landscape-up {
        margin-right: space(16);
      }
    }
  }

  &--intro {
    padding: space(32);
    color: $black;
    margin: space(24) 0 !important;

    &--inner {
      background-color: $gray;
    }
    @include for-tablet-landscape-down {
      background-color: $white;
      padding: 0;
    }

    &--heading {
      @include for-tablet-landscape-down {
        font-family: $font-primary;
        margin-bottom: space(8);
      }
    }

    &--no-padding {
      @include for-tablet-landscape-up {
        padding: 0 !important;
      }
    }

    svg {
      font-size: pxtorem(29px);
    }

    &--row {
      margin-bottom: space(32) !important;

      @include for-tablet-landscape-down {
        padding: space(12) !important;
        margin-bottom: 0 !important;

        &:nth-of-type(odd) {
          background-color: $gray;
        }
      }

      @include for-tablet-landscape-up {
        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }
    }

    &--description {
      font-size: $font-body-l;
      font-family: $font-primary;

      @include for-tablet-landscape-down {
        font-family: $font-primary;
        font-size: $font-base;
      }
    }
  }
}

.referral__button {
  border: 2px solid $black;
  padding: space(16) !important;
  margin-bottom: space(12) !important;
  background-color: $white;
  text-align: left;

  @include for-desktop-up {
    text-align: center;
    margin: 0 space(8) !important;
    height: 100%;
  }

  @include for-tablet-landscape-down {
    width: 100%;
  }

  &--active {
    background-color: $yellow;

    svg {
      color: $black;
    }
  }

  h4 {
    @include for-desktop-up {
      margin-bottom: space(24);
    }
  }

  &--small-margin {
    h4 {
      @include for-desktop-up {
        margin-bottom: space(16) !important;
      }
    }

    svg {
      @include for-desktop-up {
        margin-bottom: space(16) !important;
      }
    }
  }

  svg {
    font-size: pxtorem(36px);
    margin-bottom: space(24);

    @include for-tablet-landscape-down {
      font-size: pxtorem(25px);
      margin-bottom: 0;
    }
  }
}

.referral__step-container {
  background-color: $gray;
  margin: space(24) 0 !important;
  padding: space(32);

  &--select {
    @include for-tablet-landscape-down {
      max-width: 100% !important;
    }
  }

  &--steps {
    margin-bottom: space(6);
  }

  &--subtitle {
    margin-top: space(12);
  }

  &--full-width {
    width: 100%;
  }

  &--input {
    width: 100%;
  }

  &--label {
    margin-bottom: space(24) !important;
  }

  &--form {
    padding: space(16) !important;
  }

  &--intro {
    padding: 0 space(16) !important;
  }

  &--question {
    padding: 0 space(16);
    font-family: $font-primary;

    @include for-tablet-landscape-up {
      font-size: $font-heading-m;
      padding: 0 !important;
    }

    &--large {
      font-size: $font-body-l;
      font-family: $font-primary;
    }
  }

  &--inner {
    margin-top: space(20) !important;
  }

  &--text-area {
    @include body-m;

    border: 2px solid $black;
    resize: none;
    height: pxtorem(100px);
    padding: space(12) !important;
    margin-top: space(12);
  }

  &--other-contact {
    margin: space(20) 0 !important;

    @include for-tablet-landscape-up {
      box-sizing: border-box;
      border-left: 2px solid $gray;
    }

    .flex-col {
      margin-bottom: 0 !important;
    }

    label {
      p {
        margin-top: space(12);
      }
    }

    &--toggle {
      font-size: pxtorem(18px);
      text-decoration: underline;

      &--open {
        margin-bottom: space(12) !important;
      }
    }
  }
}

.referral__form {
  padding: space(24) !important;

  @include for-tablet-portrait-down {
    padding: space(20) 0 !important;
  }
}

.referral__terms {
  a {
    @include body-s;
    cursor: pointer;
  }

  &--heading {
    margin-bottom: space(40);

    @include for-tablet-landscape-down {
      margin-bottom: space(24);
    }
  }

  &--privacy {
    margin-top: space(16);
  }

  &--checkbox {
    margin: space(20) 0;

    label {
      color: $black !important;
    }
  }
}

.referral__confirmation {
  color: $black;
  max-width: pxtorem(1000px);

  @include for-phone-only {
    margin-bottom: space(64) !important;
  }

  &--info {
    background-color: $gray;
    padding: space(48) space(60) !important;
    margin-top: space(48);

    @include for-tablet-landscape-down {
      margin-top: space(24);
    }
  }

  &--logo {
    text-align: center;
    margin-bottom: space(24);

    @include for-tablet-landscape-down {
      margin-top: space(24);
    }
  }

  img {
    height: 100px;
  }

  h1 {
    text-align: center;
  }

  h2 {
    font-size: pxtorem(24px);
  }

  li {
    font-size: pxtorem(18px);
  }

  ul {
    margin: space(24) 0;
  }
}

.referral__other-organisation {
  padding: 0 !important;
  margin-top: space(12);
}
