@import '../../styles/colors.scss';
@import '../../styles/breakpoints.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';

select,
.select {
  @include body-m;
  width: 100%;
  max-width: none;
  position: relative;
  padding: space(20) space(40) space(20) space(20);
  border: none;
  border-radius: 4px;
  box-shadow: none;
  background-image: url('../../assets/images/icons/icon-cheveron-selection.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 12px;
  background-color: $white;
  background-size: pxtorem(35px);
  color: $black;
  -webkit-appearance: none;
  @include for-desktop-up {
    max-width: 320px;
  }
  &:disabled {
    color: $gray;
    opacity: 0.7;
    border-color: rgba($black, 0.3);
    background-image: none;
  }
}
