@import '../../styles/variables.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';

.checkbox {
  input[type='checkbox'] {
    position: absolute;
    left: -99999px;
  }

  input[type='checkbox'] + label {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0;
    font-size: pxtorem(18px);
    font-weight: 700;
    line-height: pxtorem(18px);

    svg {
      display: none;
    }

    &:focus {
      outline: 0;
      & > span {
        outline: 0;
      box-shadow: 0 0 0pt $formControlOutlineWidth $blue;
      }
    }
  }

  input[type='checkbox']:checked + label {
    svg {
      display: inline-block;
      font-size: pxtorem(20px);
      top: 4px;
      position: absolute;
      left: 4px;
      color: $black;

      @include for-phone-only {
        top: 3px;
        left: 3px;
        font-size: pxtorem(14px);
      }
    }
  }

  input[type='checkbox'] + label > span {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: space(12);
    vertical-align: middle;
    cursor: pointer;
    background-color: $white;
    border: 2px solid $black;
    border-radius: 4px;

    @include for-phone-only {
      width: 24px;
      height: 24px;
    }
  }

  input[type='checkbox'] + label span:before,
  input[type='checkbox'] + label:hover > span:before,
  input[type='checkbox']:checked + label span:before {
    display: block;
  }
}
