@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';
@import '../../styles/grid.scss';
@import '../../styles/breakpoints.scss';
@import '../../styles/fonts.scss';

@mixin marginLeft {
  margin-left: pxtorem(60px) !important;
}

.search__container {
  position: relative;
  height: auto;
  padding-bottom: space(64);
}

.search__inner-container {
  width: 100%;
  max-width: 1030px;
  height: 100%;
  position: relative;
  margin-bottom: space(24);
  padding: space(24) space(16);
  background-color: $yellow;
  z-index: 10;

  @include for-tablet-landscape-up {
    padding: space(56) space(56) space(40) space(56);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -38px;
    right: calc(25% - 38px);
    width: 0; 
    height: 0; 
    border-left: 38px solid transparent;
    border-right: 38px solid transparent;
    border-top: 38px solid $yellow;
  }
}

.search__input {
  width: 100%;

  button {
    margin: space(8) 0 space(8) space(24);
    height: 62px;

    @include for-phone-only {
      display: flex;
      align-items: center;
      margin: 0 auto;
    }
  }

  &--row {
    padding: 0 !important;
    justify-content: flex-start !important;
  }

  .input--container {
    width: 100%;
    margin: space(8) 0;
  }
}

.search__filter-keyword__input {
  width: 400px;
  @include for-tablet-portrait-down {
    max-width: 260px;
  }

  @include for-phone-only {
    max-width: 100%;
  }
}

.search__filter-location {
  display: flex;
}

.search__filter-location__label {
  @include body-l;
  padding: 0 space(20);
  margin: auto;

  @include for-phone-only {
    margin: auto 0;
  }
}

.search__filter-location__input {
  width: 208px !important;
}

.search__categories {
  @include marginLeft;
}

.search__category-subtitle {
  display: block;
  width: 100%;
  margin: space(16) 0;
  font-size: pxtorem(20px);
  text-align: center;
}

.search__category--mobile {
  margin-bottom: space(24);
}

.search__heading {
  display: block;
  margin-bottom: space(16);

  @include for-phone-only {
    text-align: center;
  }

  &--category {
    @include for-tablet-landscape-up {
      @include marginLeft;
    }
  }
}
