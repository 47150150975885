$black: #1C1C1C;
$white: #fff;
$lynch: #68748f;
$gray: #E5E5E5;

$yellow: #FFD940;
$turquoise: #83D6C9;
$light-turquoise: #D8EEEA;
$lilac: #C6C4F8;
$pink: #FF5893;
$blue: #345efd;
